import React from 'react'


/**
 * @description Template Table section 4
 * @param {object} styleProperties object holding root level style properties
 * @returns jsx
 */
function StatementTemplateTable({
    styleProperties
}) {
    // Table data structure
    const statementTableData = {
        headers: ['DATE', 'CPT', 'DESCRIPTION', 'CHARGE', 'PAYMENT', 'ADJUSTMENT', 'BALANCE'],
        rows: [
            {
                date: '11/06/24',
                cpt: 'G0027',
                description: 'Charge Entered for: Semen Analysis',
                charge: '$100.00',
                payment: '',
                adjustment: '',
                balance: ''
            },
            {
                date: '',
                cpt: '',
                description: 'Payment from: JVHL - BCN Critical Access Small Volume',
                charge: '',
                payment: '$25.00',
                adjustment: '',
                balance: ''
            },
            {
                date: '',
                cpt: '',
                description: 'Applied: Deductible Amount of $50.00',
                charge: '',
                payment: '',
                adjustment: '',
                balance: '$75.00'
            }
        ]
    };

    // Common cell styles
    const cellStyles = {
        padding: '2px 4px',
        verticalAlign: 'top',
        border: 'none',
        fontFamily: `${styleProperties.fonts.default} !important`,
        backgroundColor: 'transparent !important',
        fontSize: '8px !important'
    };


    return (
        <>
            <div style={{
                marginTop: '20px',
                width: '100%',
                minHeight: '250px',
                border: `2px solid ${styleProperties.colors.primaryTextColor}`,
                fontFamily: styleProperties.fonts.default
            }}>
                <table style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                    borderSpacing: 0,
                    backgroundColor: 'transparent',
                    border: 'none',
                    fontFamily: `${styleProperties.fonts.default} !important`
                }} className="statement-table">
                    <thead>
                        <tr style={{
                            backgroundColor: `${styleProperties.colors.tertiaryBoxBackground} !important`,
                            borderBottom: `2px solid ${styleProperties.colors.primaryTextColor}`,
                            border: 'none'
                        }}>
                            {statementTableData.headers.map((header, index) => (
                                <th key={index} style={{
                                    ...cellStyles,
                                    textAlign: index > 2 ? 'right' : 'left',
                                    fontWeight: 'normal',
                                    borderRight: 'none !important',
                                    fontSize: '10px !important'
                                }}>
                                    {header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {statementTableData.rows.map((row, index) => (
                            <tr key={index} style={{
                                border: 'none',
                                backgroundColor: 'transparent !important'
                            }}>
                                <td style={{ ...cellStyles }}>{row.date}</td>
                                <td style={{ ...cellStyles }}>{row.cpt}</td>
                                <td style={{ ...cellStyles }}>{row.description}</td>
                                <td style={{ ...cellStyles, textAlign: 'right' }}>{row.charge}</td>
                                <td style={{ ...cellStyles, textAlign: 'right' }}>{row.payment}</td>
                                <td style={{ ...cellStyles, textAlign: 'right' }}>{row.adjustment}</td>
                                <td style={{ ...cellStyles, textAlign: 'right' }}>{row.balance}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div style={{
                marginTop: '5px',
                marginBottom: '5px',
                display: 'flex',
                flexDirection: 'column',
                gap: '1px'
            }}>
                <p style={{
                    margin: 0,  // Remove default margins
                    padding: 0,
                    fontSize: '11px',
                    fontFamily: styleProperties.fonts.default,
                    color: '#000'
                }}>
                    ** These tests were ordered by Provider John Smith MD
                </p>
                <p style={{
                    margin: 0,  // Remove default margins
                    padding: 0,
                    fontSize: '11px',
                    fontFamily: styleProperties.fonts.default,
                    color: '#000'
                }}>
                    ** These samples were collected from New Discover Labs LLC
                </p>
            </div>

            {/* Updated scoped styles with higher specificity */}
            <style>
                {`
						table.statement-table,
						table.statement-table th,
						table.statement-table td {
							border: none !important;
							background-color: transparent !important;
							font-size: 11px !important;
							font-family: ${styleProperties.fonts.default} !important;
						}
						
						table.statement-table tr,
						table.statement-table tr td,
						table.statement-table tbody tr {
							height: auto !important;
							min-height: 23px !important;
							max-height: none !important;
							line-height: normal !important;
						}
						
						table.statement-table th {
							border-right: none !important;
							font-weight: normal !important;
							font-size: 12px !important;
							height: 25px !important;
                            border-bottom: 2px solid ${styleProperties.colors.primaryTextColor} !important;
						}
						
						table.statement-table thead tr {
							background-color: ${styleProperties.colors.tertiaryBoxBackground} !important;
							height: auto !important;
						}
						
						table.statement-table tbody tr {
							background-color: transparent !important;
						}

						/* Additional specificity for row height */
						body table.statement-table tr,
						body table.statement-table tbody tr,
						body table.statement-table thead tr {
							height: auto !important;
							min-height: 23px !important;
							max-height: none !important;
						}
					`}
            </style>
        </>
    )
}

export default StatementTemplateTable
