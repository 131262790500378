import React from 'react';
import PropTypes from 'prop-types';
import EditorIconWrapper from './EditorIconWrapper';

/**
 * FooterAddressCard Component
 * 
 * This includes both the patient's address and the practice's address.
 * This area will be available see in the envelops window.
 * 
 * @component
    * @param { Object } props - Component props
    * @param { Object } props.styleProperties - Object containing style configurations
    * @param { Object } props.templateConfig - Object containing current editable state of the template
    * @param { func } props.handleEditorOpenClick - Callback to trigger when user clicks on edit icon
 * @returns {JSX.Element} Rendered address section for the statement footer
 */
function FooterAddressCard({ styleProperties, templateConfig, handleEditorOpenClick }) {
    return (
        <div style={{
            position: 'absolute',
            bottom: '5px',
            left: '70px',
            right: '70px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            fontFamily: styleProperties.fonts.default,
            height: '120px',
            paddingTop: "5px",
        }}>
            {/* Patient Address - Left Side */}
            <div style={{
                flex: '0 0 45%',
                display: 'flex',
                flexDirection: 'column',
                gap: '4px'
            }}>
                {/* Patient Name and Account */}
                <div style={{
                    marginBottom: '4px'
                }}>
                    <div style={{
                        fontSize: '13px',
                        fontWeight: 600,
                        color: '#000'
                    }}>
                        JOHN DOE
                        <span style={{
                            marginLeft: '20px',
                            fontWeight: 'normal'
                        }}>
                            12345678
                        </span>
                    </div>
                </div>

                {/* Patient Address */}
                <div style={{
                    fontSize: '12px',
                    lineHeight: '1.4',
                    color: '#000'
                }}>
                    <div>123 MAIN STREET</div>
                    <div>ANYTOWN, NY 12345</div>
                </div>
            </div>

            {/* Practice Address - Right Side */}
            <EditorIconWrapper
                onEdit={() => handleEditorOpenClick("statement_from_address")}
                iconSize="small"
                iconColor="primary"
                disabled={false}
                editEnabled={templateConfig.editEnabled}
            >
                <div style={{
                    flex: '0 0 45%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                    alignItems: 'flex-end'
                }}>
                    <div style={{
                        fontSize: '13px',
                        fontWeight: 600,
                        marginBottom: '4px',
                        textAlign: 'right'
                    }}>
                        {templateConfig.statement_from_address?.mail_from_name?.value ||
                            templateConfig.statement_from_address?.mail_from_name?.default}
                    </div>
                    <div style={{
                        fontSize: '12px',
                        lineHeight: '1.4',
                        color: '#000',
                        textAlign: 'right'
                    }}>
                        <div>
                            {templateConfig.statement_from_address?.text_1?.value ||
                                templateConfig.statement_from_address?.text_1?.default}
                        </div>
                        {(templateConfig.statement_from_address?.text_2?.value ||
                            templateConfig.statement_from_address?.text_2?.default) && (
                                <div>
                                {templateConfig.statement_from_address.text_2.value ||
                                    templateConfig.statement_from_address.text_2.default}
                                </div>
                            )}
                        {(templateConfig.statement_from_address?.text_3?.value ||
                            templateConfig.statement_from_address?.text_3?.default) && (
                                <div>
                                {templateConfig.statement_from_address.text_3.value ||
                                    templateConfig.statement_from_address.text_3.default}
                                </div>
                            )}
                    </div>
                </div>
            </EditorIconWrapper>
        </div>
    );
}

FooterAddressCard.propTypes = {
    styleProperties: PropTypes.object.isRequired,
    templateConfig: PropTypes.object.isRequired,
    handleEditorOpenClick: PropTypes.func.isRequired
};

export default FooterAddressCard;