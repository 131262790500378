import React from 'react'
import ComputerRoundedIcon from '@mui/icons-material/ComputerRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import HelpCenterRoundedIcon from '@mui/icons-material/HelpCenterRounded';
import EditorIconWrapper from './EditorIconWrapper';
import { DateTime } from 'luxon';


/**
 * @description Template Section 3, which renders Billing related info
 * @param {object} styleProperties object holding root level style properties
 * @returns jsx
 */
function HeaderSectionBillDetails({
    styleProperties,
    handleEditorOpenClick,
    templateConfig
}) {
    return (
        <div id='template-section-3' style={{
            display: 'flex',
            width: '100%',
            gap: '20px',
            marginTop: '5px'
        }}>
            {/* Left Section - 35% */}
            <div style={{
                width: '35%',
                position: 'relative'
            }}>
                <div style={{ marginTop: "40px" }} />
                {/* Badge */}
                <div style={{
                    position: 'absolute',
                    top: '15px',
                    backgroundColor: styleProperties.colors.primaryBoxBackground,
                    color: '#fff',
                    padding: '5px 15px',
                    fontSize: '10px',
                    fontFamily: styleProperties.fonts.default,
                    fontWeight: 500,
                    zIndex: 1
                }}>
                    BILL SUMMARY
                </div>

                {/* Main Box */}
                <div style={{
                    border: `10px solid ${styleProperties.colors.secondaryBoxBackground}`,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0',
                    position: 'relative',
                    marginTop: '10px'
                }}>
                    {/* First Inner Box */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        alignItems: 'center',
                        textAlign: 'center',
                        padding: '10px 0px'
                    }}>
                        <span style={{
                            fontSize: '16px',
                            color: styleProperties.colors.primaryTextColor,
                            fontWeight: 600,
                            fontFamily: styleProperties.fonts.default
                        }}>
                            Total Payment Due
                        </span>
                        <span style={{
                            fontSize: '12px',
                            color: '#000',
                            fontFamily: styleProperties.fonts.default
                        }}>
                            Your balance due is:
                        </span>
                        <span style={{
                            fontSize: '24px',
                            fontWeight: 600,
                            color: '#000',
                            fontFamily: styleProperties.fonts.default
                        }}>
                            $50.00
                        </span>
                    </div>
                    {/* Divider that extends to parent borders */}
                    <div style={{
                        width: 'calc(100% + 20px)',
                        height: '10px',
                        backgroundColor: styleProperties.colors.secondaryBoxBackground,
                        margin: '0 -10px'
                    }} />

                    {/* Second Inner Box */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        alignItems: 'center',
                        textAlign: 'center',
                        padding: '10px 0px'
                    }}>
                        <span style={{
                            fontSize: '16px',
                            color: styleProperties.colors.primaryTextColor,
                            fontWeight: 600,
                            fontFamily: styleProperties.fonts.default
                        }}>
                            Payment Due By:
                        </span>
                        <EditorIconWrapper
                            onEdit={() => handleEditorOpenClick("payment_due_by_duration")}
                            iconSize="small"
                            iconColor="primary"
                            disabled={false}
                            editEnabled={templateConfig.editEnabled}
                        >
                            <span style={{
                                fontSize: '20px',
                                fontWeight: 600,
                                color: '#000',
                                fontFamily: styleProperties.fonts.default
                            }}>
                                {DateTime.now()
                                    .plus({
                                        days: templateConfig.payment_due_by_duration?.value ||
                                            templateConfig.payment_due_by_duration?.default ||
                                            0
                                    })
                                    .toFormat('MM/dd/yyyy')}
                            </span>
                        </EditorIconWrapper>
                    </div>
                </div>
            </div>

            {/* Right Section - 65% */}
            <div style={{
                width: '65%'
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    width: "100%",
                }}>
                    <div style={{
                        width: "45%",
                    }}>
                        <div style={{
                            width: "100%",
                            height: "50px",
                            background: styleProperties.colors.primaryTextColor,
                            clipPath: "polygon(0 0, calc(100% - 20px) 0, 100% 50%, calc(100% - 20px) 100%, 0 100%, 0 0)",
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            padding: '0 20px'
                        }} >
                            <ComputerRoundedIcon sx={{
                                color: '#fff',
                                fontSize: '24px'
                            }} />
                            <span style={{
                                color: '#fff',
                                fontSize: '16px',
                                fontWeight: 500,
                                fontFamily: styleProperties.fonts.default,
                                letterSpacing: '0.5px'
                            }}>
                                PAY ONLINE
                            </span>
                        </div>
                    </div>
                    <div style={{
                        width: "54%",
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        gap: '5px'
                    }}>
                        <EditorIconWrapper
                            onEdit={() => handleEditorOpenClick("pay_online_text_a")}
                            iconSize="small"
                            iconColor="primary"
                            disabled={false}
                            editEnabled={templateConfig.editEnabled}
                        >
                            {/* Normal text */}
                            <p style={{
                                fontSize: '13px',
                                lineHeight: '1.5',
                                margin: '0',
                                fontFamily: styleProperties.fonts.default
                            }}>
                                {templateConfig.pay_online_text_a?.value || templateConfig.pay_online_text_a?.default}
                            </p>
                        </EditorIconWrapper>
                        <p style={{
                            fontSize: '16px',
                            lineHeight: '1.5',
                            margin: '0',
                            fontFamily: styleProperties.fonts.default
                        }}>
                            Account # 59487260
                        </p>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: 'center',
                            gap: '10px'
                        }}>
                            <div style={{
                                flex: '1',
                                fontFamily: styleProperties.fonts.default
                            }}>
                                <h6 style={{
                                    fontSize: '17px',
                                    fontWeight: 600,
                                    margin: '0 0 10px 0',
                                }}>
                                    Scan the QR Code to make online payments
                                </h6>
                            </div>
                            <EditorIconWrapper
                                onEdit={() => handleEditorOpenClick("qr_code")}
                                iconSize="small"
                                iconColor="primary"
                                disabled={false}
                                editEnabled={templateConfig.editEnabled}
                            >
                                <div style={{
                                    flex: '0 0 auto', // QR code takes only necessary space
                                    width: '75px', // Set a fixed size for QR code
                                    height: '75px',
                                    backgroundColor: styleProperties.colors.tertiaryBoxBackground, // Placeholder color
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <img
                                        src={templateConfig.qr_code?.value || templateConfig.qr_code?.default}
                                        alt="Practice Payment QR Code"
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                            objectFit: 'contain',
                                            transition: 'transform 0.2s ease'
                                        }}
                                    />
                                </div>
                            </EditorIconWrapper>
                        </div>
                    </div>
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    width: "100%",
                }}>
                    <div style={{
                        width: "45%",
                    }}>
                        <div style={{
                            width: "100%",
                            height: "50px",
                            background: styleProperties.colors.secondaryBoxBackground,
                            clipPath: "polygon(0 0, calc(100% - 20px) 0, 100% 50%, calc(100% - 20px) 100%, 0 100%, 0 0)",
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                            padding: '0 20px'
                        }} >
                            <EmailRoundedIcon sx={{
                                color: '#fff',
                                fontSize: '24px'
                            }} />
                            <span style={{
                                color: '#fff',
                                fontSize: '16px',
                                fontWeight: 500,
                                fontFamily: styleProperties.fonts.default,
                                letterSpacing: '0.5px'
                            }}>
                                PAY BY MAIL
                            </span>
                        </div>
                    </div>
                    <div style={{
                        width: "54%",
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        gap: '5px'
                    }}>
                        <EditorIconWrapper
                            onEdit={() => handleEditorOpenClick("pay_by_mail_text")}
                            iconSize="small"
                            iconColor="primary"
                            disabled={false}
                            editEnabled={templateConfig.editEnabled}
                        >
                            {/* Normal text */}
                            <p style={{
                                fontSize: '13px',
                                lineHeight: '1.5',
                                margin: '0',
                                fontFamily: styleProperties.fonts.default,
                                wordWrap: 'break-word',     // Ensures words break and wrap
                                wordBreak: 'break-word',    // Handles long words
                                whiteSpace: 'pre-wrap',     // Preserves whitespace and wraps
                                overflow: 'hidden',         // Prevents overflow
                                textOverflow: 'ellipsis',   // Shows ellipsis if text overflows
                                maxWidth: '100%',           // Ensures text stays within parent
                            }}>
                                {templateConfig.pay_by_mail_text?.value || templateConfig.pay_by_mail_text?.default}
                            </p>
                        </EditorIconWrapper>
                    </div>
                </div>

                {/* Help Section */}
                <div style={{
                    marginTop: '20px',
                    padding: '8px 12px',
                    border: `2px solid ${styleProperties.colors.tertiaryBoxBackground}`,
                    borderRadius: '4px',
                    width: '100%'
                }}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        gap: '8px'
                    }}>
                        <div>
                            <HelpCenterRoundedIcon sx={{
                                color: styleProperties.colors.primaryBoxBackground,
                                fontSize: '50px',
                                marginTop: '0px'
                            }} />
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px'
                        }}>
                            <p style={{
                                margin: 0,
                                fontSize: '14px',
                                fontWeight: 600,
                                color: styleProperties.colors.primaryTextColor,
                                fontFamily: styleProperties.fonts.default
                            }}>
                                Have questions about your bill?
                            </p>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '2px',
                                fontSize: '12px',
                                fontFamily: styleProperties.fonts.default,
                                lineHeight: '1.2'
                            }}>
                                <span>
                                    Call us at{' '}
                                    <EditorIconWrapper
                                        onEdit={() => handleEditorOpenClick("phone")}
                                        iconSize="small"
                                        iconColor="primary"
                                        disabled={false}
                                        editEnabled={templateConfig.editEnabled}
                                    >
                                        <span style={{ fontWeight: 500 }}>
                                            {templateConfig.phone?.value || templateConfig.phone?.default}
                                        </span>
                                    </EditorIconWrapper>
                                </span>
                                <span>
                                    Email us at{' '}
                                    <EditorIconWrapper
                                        onEdit={() => handleEditorOpenClick("email")}
                                        iconSize="small"
                                        iconColor="primary"
                                        disabled={false}
                                        editEnabled={templateConfig.editEnabled}
                                    >
                                        <span style={{
                                            color: styleProperties.colors.primaryTextColor,
                                            textDecoration: 'underline'
                                        }}>
                                            {templateConfig.email?.value || templateConfig.email?.default}
                                        </span>
                                    </EditorIconWrapper>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderSectionBillDetails
