import React from "react";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Update";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import TuneIcon from "@mui/icons-material/Tune";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SendIcon from '@mui/icons-material/Send';
import ModeIcon from '@mui/icons-material/Mode';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import SaveIcon from '@mui/icons-material/Save';
import NoteIcon from '@mui/icons-material/Note';
import SearchIcon from '@mui/icons-material/Search';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from "@mui/material/styles";

/**
 * @name CommonButton
 * @param {string} variant 'contained' || 'outlined' || 'text' 
 * @param {boolean} disabled Boolean
 * @param {function} onClick
 * @icon {string} name of the icon as string 
 * @returns jsx button element 
 */


const getBackground = (variant, disabled) => {
    let backgroundColor;
    switch (variant) {
        case "contained":
            if (!disabled) {
                backgroundColor = "#1479BB !important";
            } else {
                backgroundColor = "#BDBDBD";
            }
            return backgroundColor;
        case "outlined":           
                backgroundColor = "transparent !important";
            return backgroundColor;
        case "text":
            
            if (!disabled) {
                backgroundColor = "#F5F5F5 !important";
            } else {
                backgroundColor = "transparent";
            }
            return backgroundColor;
        default:
            return backgroundColor;
    }
};

const getHoverBackground = (variant, disabled) => {
    let hoverBackground;
    switch (variant) {
        case "contained":
            if (!disabled) {
                hoverBackground = `linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #1479BB `;
            } else {
                hoverBackground = "#BDBDBD important";
            }
            return hoverBackground;
        case "outlined":
            hoverBackground = "#d9e3ea !important";
            return hoverBackground;
        case "text":
            hoverBackground = "#fff !important";
            return hoverBackground;
        default:
            return hoverBackground;
    }
};

const getTextColor = (variant, disabled) => {
    let textColor;
    switch (variant) {
        case "contained":
            textColor = "#fff !important";
            return textColor;
        case "outlined":
            if (!disabled) {
              //  textColor = "var(--grey-g-800, #424242)";
              textColor="#1479BB !important"
            } 
            else {
                textColor = "#BDBDBD !important";
            }
            return textColor;
        case "text":
            if (!disabled) {
                textColor = "#1479BB !important";
            } else {
                textColor = 'var(--grey-g-400, #BDBDBD) !important';
            }
            return textColor;
        default:
            return textColor;
    }
};

const StyledButton = styled(Button)(({ variant, disabled, width, height }) => ({
    backgroundColor: getBackground(variant, disabled),
    color: getTextColor(variant, disabled),
    borderColor: getTextColor(variant, disabled),
    display: "inline-flex",
    padding: "8px 16px",
    justifyContent: "center",
    width: width ?? 'auto',
    minHeight: '36px !important',
    height: height ?? "36px !important",
    alignItems: "center",
    gap: "10px",
    borderRadius: "4px",
    fontFamily: "Lato !important",
    fontSize: "14px !important",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px !important",
    transition: "all 0.3s ease",
    boxShadow: "none !important",
    textTransform: 'none !important',
    WebkitFontSmoothing: "antialiased",
    "&:hover": {
        background: getHoverBackground(variant, disabled),
    },
}));

const CommonButton = (props) => {
    const { icon, label, variant, onClick, onBlur, disabled, width, id } = props;

    function getIcon(iconName) {
        const IconStyle = {
            color: getTextColor(variant, disabled),
        };

        switch (iconName) {
            case "add":
                return <AddIcon style={IconStyle} />;
            case "delete":
                return <DeleteIcon style={IconStyle} />;
            case "update":
                return <UpdateIcon style={IconStyle} />;
            case "edit":
                return <ModeEditOutlineRoundedIcon style={IconStyle} />;
            case "advanced":
                return <TuneIcon style={IconStyle} />;
            case "export":
                return <UploadFileIcon style={IconStyle} />;
            case "download":
                return <DownloadIcon style={IconStyle} />;
                case "upload":
                return <UploadIcon style={IconStyle} />;
            case "print":
                return <LocalPrintshopOutlinedIcon style={IconStyle} />;
            case 'eye':
                return <RemoveRedEyeOutlinedIcon style={IconStyle} /> 
            case 'send':
                return <SendIcon style={IconStyle} />;
            case 'pencil':
                return <ModeIcon style={IconStyle} />;
            case 'save':
                return <SaveIcon style={IconStyle} />;
            case 'contact':
                return <ContactPageIcon style={IconStyle} />;
            case 'template':
                return <NoteIcon style={IconStyle} />;
            case 'search':
                return <SearchIcon style={IconStyle} />;
            case 'assignment':
                return <AssignmentRoundedIcon style={IconStyle} />;
            case 'cancel':
                return <CloseRoundedIcon style={IconStyle} />;
            case 'reset':
                return <RestartAltIcon style={IconStyle} />;
            default:
                return null;
        }
    }

    return (
        <StyledButton
            variant={variant}
            startIcon={getIcon(icon)}
            onClick={onClick}
            disabled={disabled ? disabled : false}
            width={width}
            onBlur={onBlur}
            id={id ?? 'common-btn'}
        >
            {label}
        </StyledButton>
    );
};

export default CommonButton;
