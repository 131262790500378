import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import TextInput from "../../../commons/input/input";
import TextAreaInput from '../../../commons/textarea/textarea';
import CommonButton from '../../../commons/Buttons';


const DraggablePaper = (props) => (
    <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
    >
        <Paper {...props} />
    </Draggable>
);



/**
 * TemplateEditor Component
 * This component provides a dialog interface for editing statement templates. It supports various field types including text, long text, file uploads, and nested objects for mail and address information.
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.openTemplateEditor - Controls the visibility of the template editor dialog.
 * @param {Function} props.handleEditorClose - Callback function to handle the closing of the editor dialog.
 * @param {string} props.currentEditField - The key of the field currently being edited in the template configuration.
 * @param {Object} props.templateConfig - The configuration object for the template fields.
 * @param {Function} props.setTemplateConfig - Function to update the template configuration state.
 * @param {Function} props.setLogoFileInput - Function to set the file input for the logo.
 * @param {Function} props.setQrFileInput - Function to set the file input for the QR code.
 * 
 * @returns {JSX.Element} The rendered TemplateEditor component.
 */
const TemplateEditor = ({
    openTemplateEditor,
    handleEditorClose,
    currentEditField,
    templateConfig,
    setTemplateConfig,
    setLogoFileInput,
    setQrFileInput,
    originalConfigRef
}) => {
    // Guard clause for invalid field
    if (!currentEditField || !templateConfig[currentEditField]) {
        return null;
    }

    const currentField = templateConfig[currentEditField];

    const fileInputRef = React.useRef(null);
    const [dragOver, setDragOver] = React.useState(false);

    /**
     * @description Handle Value change function for types other than file 
     * @param {string} fieldName name of the editing field
     * @param {*} e native html change event
     * @param {*} nestedField incase the value being changed from a nested object the name of the nested field
     * @returns 
     */
    const onFieldUpdate = (fieldName, e, nestedField = null) => {
        const value = e.target.value;
        const field = templateConfig[fieldName];

        // Validate field existence
        if (!field) {
            console.warn(`Field ${fieldName} not found in templateConfig`);
            return;
        }

        // Handle nested mailTo fields
        if (field.type === "mail_to_object" && nestedField) {
            const nestedConfig = field[nestedField];

            // Validate nested field length if applicable
            if (nestedConfig.max_length && value.length > nestedConfig.max_length) {
                return;
            }

            setTemplateConfig(prev => ({
                ...prev,
                [fieldName]: {
                    ...prev[fieldName],
                    [nestedField]: {
                        ...prev[fieldName][nestedField],
                        value
                    }
                }
            }));
            return;
        }

        // Handle nested mailTo fields
        if (field.type === "statement_from_address_object" && nestedField) {
            const nestedConfig = field[nestedField];

            // Validate nested field length if applicable
            if (nestedConfig.max_length && value.length > nestedConfig.max_length) {
                return;
            }

            setTemplateConfig(prev => ({
                ...prev,
                [fieldName]: {
                    ...prev[fieldName],
                    [nestedField]: {
                        ...prev[fieldName][nestedField],
                        value
                    }
                }
            }));
            return;
        }

        // Regular field validation and update
        if (["text", "long_text"].includes(field.type) && field.max_length && value.length > field.max_length) {
            return;
        }

        if (field.type === "number" && value) {
            const numValue = Number(value);
            if (numValue < field.min || numValue > field.max) {
                return;
            }
        }

        setTemplateConfig(prev => ({
            ...prev,
            [fieldName]: {
                ...prev[fieldName],
                value
            }
        }));
    };


    /**
     * @description Handle change for file upload
     * This is used for practice_log and qr_code
     * @param {*} file 
     * @returns 
     */
    const handleFileUpload = (file) => {
        if (!file) return;

        // Create object URL for the file
        const fileUrl = URL.createObjectURL(file);

        // Update template config with file info
        setTemplateConfig(prev => ({
            ...prev,
            [currentEditField]: {
                ...prev[currentEditField],
                value: fileUrl,
                fileName: file.name
            }
        }));

        if (currentField.name == "qr_code") {
            setQrFileInput(file);
        } else if (currentField.name == "logo") {
            setLogoFileInput(file)
        }
    };

    // Handle file selection via input
    const handleFileSelect = (event) => {
        const file = event.target.files?.[0];
        handleFileUpload(file);
    };

    // Handle file drop
    const handleDrop = (event) => {
        event.preventDefault();
        setDragOver(false);

        const file = event.dataTransfer.files?.[0];
        handleFileUpload(file);
    };

    // Handle drag events
    const handleDragOver = (event) => {
        event.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = () => {
        setDragOver(false);
    };

    // Handle click on drop area
    const handleDropAreaClick = () => {
        fileInputRef.current?.click();
    };


    /**
     * @description This render function renders elements based on the currently editing fields type like textfield for text etc
     * @returns 
     */
    const renderField = () => {
        switch (currentField.type) {
            case 'text':
            case 'number':
                return (
                    <TextInput
                        type={currentField.type}
                        name={currentField.name}
                        required={currentField.required}
                        value={currentField.value}
                        onValueChange={(e) => onFieldUpdate(currentEditField, e)}
                        helperText={currentField.helper_text}
                    />
                );
            case 'long_text':
                return (
                    <>
                        <TextAreaInput
                            name={currentField.name}
                            required={currentField.required}
                            value={currentField.value}
                            onValueChange={(e) => onFieldUpdate(currentEditField, e)}
                        />
                        {currentField.helper_text}
                    </>
                )
            case "file":
                return (
                    <>
                        <div
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onClick={handleDropAreaClick}
                            style={{
                                cursor: "pointer",
                                margin: 30,
                                height: "80px",
                                border: "2px dashed #cccccc",
                                borderRadius: "4px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: dragOver ? "#deebff" : "#f5f6f8",
                            }}
                        >
                            {currentField.fileName ? (
                                <div>{currentField.fileName}</div>
                            ) : (
                                <div>Click to Select or Drop a File here</div>
                            )}
                        </div>
                        <input
                            ref={fileInputRef}
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleFileSelect}
                            accept={currentField.accept}
                        />
                    </>
                );
            case "mail_to_object":
                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '15px'
                    }}>
                        {/* Practice Name */}
                        <TextInput
                            type="text"
                            name="mail_to_name"
                            label="Practice Name"
                            required={currentField.mail_to_name.required}
                            value={currentField.mail_to_name.value}
                            onValueChange={(e) => onFieldUpdate(currentEditField, e, 'mail_to_name')}
                            helperText={`Max ${currentField.mail_to_name.max_length} characters`}
                        />

                        {/* Address Line 1 */}
                        <TextInput
                            type="text"
                            name="text_1"
                            label="Address Line 1"
                            required={currentField.text_1.required}
                            value={currentField.text_1.value}
                            onValueChange={(e) => onFieldUpdate(currentEditField, e, 'text_1')}
                            helperText={`Max ${currentField.text_1.max_length} characters`}
                        />

                        {/* Address Line 2 */}
                        <TextInput
                            type="text"
                            name="text_2"
                            label="Address Line 2"
                            required={currentField.text_2.required}
                            value={currentField.text_2.value}
                            onValueChange={(e) => onFieldUpdate(currentEditField, e, 'text_2')}
                            helperText={`Max ${currentField.text_2.max_length} characters`}
                        />

                        {/* Address Line 3 */}
                        <TextInput
                            type="text"
                            name="text_3"
                            label="Address Line 3"
                            required={currentField.text_3.required}
                            value={currentField.text_3.value}
                            onValueChange={(e) => onFieldUpdate(currentEditField, e, 'text_3')}
                            helperText={`Max ${currentField.text_3.max_length} characters`}
                        />
                    </div>
                );
            case "statement_from_address_object":
                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '15px'
                    }}>
                        {/* Practice Name */}
                        <TextInput
                            type="text"
                            name="mail_from_name"
                            label="Practice Name"
                            required={currentField.mail_from_name.required}
                            value={currentField.mail_from_name.value}
                            onValueChange={(e) => onFieldUpdate(currentEditField, e, 'mail_from_name')}
                            helperText={`Max ${currentField.mail_from_name.max_length} characters`}
                        />

                        {/* Address Line 1 */}
                        <TextInput
                            type="text"
                            name="text_1"
                            label="Address Line 1"
                            required={currentField.text_1.required}
                            value={currentField.text_1.value}
                            onValueChange={(e) => onFieldUpdate(currentEditField, e, 'text_1')}
                            helperText={`Max ${currentField.text_1.max_length} characters`}
                        />

                        {/* Address Line 2 */}
                        <TextInput
                            type="text"
                            name="text_2"
                            label="Address Line 2"
                            required={currentField.text_2.required}
                            value={currentField.text_2.value}
                            onValueChange={(e) => onFieldUpdate(currentEditField, e, 'text_2')}
                            helperText={`Max ${currentField.text_2.max_length} characters`}
                        />

                        {/* Address Line 3 */}
                        <TextInput
                            type="text"
                            name="text_3"
                            label="Address Line 3"
                            required={currentField.text_3.required}
                            value={currentField.text_3.value}
                            onValueChange={(e) => onFieldUpdate(currentEditField, e, 'text_3')}
                            helperText={`Max ${currentField.text_3.max_length} characters`}
                        />
                    </div>
                );
            default:
                return null;
        }
    };


    /**
     * Reverts the current field's value to its original state from when the template was first loaded
     * 
     * @function handleRevert
     * @description
     * Handles reverting different types of fields:
     * - File fields (logo, qr_code): Resets both value and file input state
     * - Nested objects (mail_to, statement_from_address): Reverts all nested field values
     * - Regular fields: Reverts simple value fields
     */
    const handleRevert = () => {
        if (!originalConfigRef?.current?.[currentEditField]) return;

        const originalField = originalConfigRef.current[currentEditField];

        // Handle nested objects (mail_to and statement_from_address)
        if (["mail_to_object", "statement_from_address_object"].includes(currentField.type)) {
            const nestedFields = ['mail_to_name', 'mail_from_name', 'text_1', 'text_2', 'text_3'];

            setTemplateConfig(prev => ({
                ...prev,
                [currentEditField]: {
                    ...prev[currentEditField],
                    ...nestedFields.reduce((acc, field) => {
                        if (originalField[field]) {
                            acc[field] = {
                                ...prev[currentEditField][field],
                                value: originalField[field].value
                            };
                        }
                        return acc;
                    }, {})
                }
            }));
        }
        // Handle file type fields
        else if (currentField.type === 'file') {
            setTemplateConfig(prev => ({
                ...prev,
                [currentEditField]: {
                    ...prev[currentEditField],
                    value: originalField.value,
                    fileName: ""
                }
            }));

            // Reset file inputs based on field name
            if (currentEditField === 'logo') {
                setLogoFileInput(null);
            } else if (currentEditField === 'qr_code') {
                setQrFileInput(null);
            }

            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
        // Handle all other regular fields
        else {
            setTemplateConfig(prev => ({
                ...prev,
                [currentEditField]: {
                    ...prev[currentEditField],
                    value: originalField.value
                }
            }));
        }
    };


    /**
     * Determines if the revert button should be disabled based on field value comparison
     * 
     * @function isRevertDisabled
     * @returns {boolean} Returns true if:
     *  - Original or current field configuration is missing
     *  - Field values match (no changes to revert)
     *  - For nested objects, all subfield values match
     */
    const isRevertDisabled = () => {
        if (!originalConfigRef?.current?.[currentEditField] || !templateConfig?.[currentEditField]) {
            return true;
        }

        const originalField = originalConfigRef.current[currentEditField];
        const currentField = templateConfig[currentEditField];

        // Handle nested objects
        if (["mail_to_object", "statement_from_address_object"].includes(currentField.type)) {
            const nestedFields = ['mail_to_name', 'mail_from_name', 'text_1', 'text_2', 'text_3'];
            return nestedFields.every(field =>
                originalField[field]?.value === currentField[field]?.value
            );
        }

        // Handle regular fields
        return originalField.value === currentField.value;
    };

    return (
        <Dialog
            open={openTemplateEditor}
            onClose={handleEditorClose}
            PaperComponent={DraggablePaper}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle
                style={{ cursor: 'move' }}
                id="draggable-dialog-title"
            >
                {currentField.label || ''}
            </DialogTitle>

            <DialogContent>
                <div style={{
                    minWidth: '400px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '15px'
                }}>
                    {renderField()}
                </div>
            </DialogContent>

            <DialogActions>
                <CommonButton
                    icon="reset"  // Using cancel icon for revert
                    label="Revert"
                    variant="outlined"
                    color="warning"
                    disabled={isRevertDisabled()}
                    onClick={handleRevert}
                />
                <CommonButton
                    icon="cancel"
                    label="Close"
                    variant="contained"
                    onClick={handleEditorClose}
                />
            </DialogActions>
        </Dialog>
    );
};

export default TemplateEditor;