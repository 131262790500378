import React from 'react'


/**
 * @description Template Header section 2 or B, which renders just the page number
 * @param {object} styleProperties object holding root level style properties
 * @returns jsx
 */
function HeaderSectionB({
    styleProperties
}) {
    return (
        <div id='template-section-2'
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '10px',
                fontFamily: styleProperties.fonts.default
            }}>
            <span style={{
                fontSize: '14px',
                color: '#000'
            }}>
                PAGE 1 OF 1
            </span>
        </div>
    )
}

export default HeaderSectionB
