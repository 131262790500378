import React from 'react'
import EditorIconWrapper from './EditorIconWrapper'

/**
 * @description Template Header section 1, which renders Logo, Practice name, and Patient basics
 * @param {object} styleProperties object holding root level style properties
 * @param {function} handleEditorOpenClick function open editor modal
 * @returns jsx
 */
function HeaderSectionA({
    styleProperties,
    templateConfig,
    handleEditorOpenClick
}) {
    return (
        <>
            {/* SECTION - 1 Practice Name & Patient Name */}
            <div id='template-section-1'
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    width: '100%',
                    marginBottom: '20px',
                    fontFamily: styleProperties.fonts.default
                }}>
                {/* Left Section */}
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px'
                }}>
                    {/* Logo Container with subtle shadow and border */}
                    <EditorIconWrapper
                        onEdit={() => handleEditorOpenClick("logo")}
                        iconSize="small"
                        iconColor="primary"
                        disabled={false}
                        editEnabled={templateConfig.editEnabled}
                    >
                        <div style={{
                            width: '60px',
                            height: '60px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '0px',
                            backgroundColor: '#fff',
                            borderRadius: '4px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                            border: `1px solid ${styleProperties.colors.tertiaryBoxBackground}`
                        }}>
                            <img
                                src={templateConfig.logo?.value || templateConfig.logo?.default}
                                alt="Practice Logo"
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    objectFit: 'contain',
                                    transition: 'transform 0.2s ease'
                                }}
                            />
                        </div>
                    </EditorIconWrapper>
                    {/* Practice Name with improved typography */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '2px'
                    }}>
                        <EditorIconWrapper
                            onEdit={() => handleEditorOpenClick("practice_name")}
                            iconSize="small"
                            iconColor="primary"
                            disabled={false}
                            editEnabled={templateConfig.editEnabled}
                        >
                        <span style={{
                            fontSize: '28px',
                            color: styleProperties.colors.primaryTextColor,
                            fontWeight: 600,
                            fontFamily: styleProperties.fonts.default,
                            letterSpacing: '-0.3px',
                            lineHeight: '1.2',
                            textTransform: 'uppercase'
                        }}>
                                {templateConfig.practice_name?.value || templateConfig.practice_name?.default}
                            </span>
                        </EditorIconWrapper>
                    </div>
                </div>

                {/* Right Section */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    textAlign: 'left',
                    fontFamily: styleProperties.fonts.default,
                    fontWeight: 200,
                }}>
                    <div style={{
                        fontSize: '14px',
                        marginBottom: '4px',
                        display: 'flex',
                        gap: '8px'
                    }}>
                        <span style={{
                            color: styleProperties.colors.primaryTextColor,
                            minWidth: '120px',
                            fontFamily: styleProperties.fonts.default
                        }}>PATIENT:</span>
                        <span>John Doe</span>
                    </div>
                    <div style={{
                        fontSize: '14px',
                        marginBottom: '4px',
                        display: 'flex',
                        gap: '8px'
                    }}>
                        <span style={{
                            color: styleProperties.colors.primaryTextColor,
                            minWidth: '120px',
                            fontFamily: styleProperties.fonts.default
                        }}>ACCOUNT#:</span>
                        <span>12345678</span>
                    </div>
                    <div style={{
                        fontSize: '14px',
                        display: 'flex',
                        gap: '8px'
                    }}>
                        <span style={{
                            color: styleProperties.colors.primaryTextColor,
                            minWidth: '120px',
                            fontFamily: styleProperties.fonts.default
                        }}>STATEMENT DATE:</span>
                        <span>01/15/2024</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeaderSectionA
