import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { useCallback, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { styled } from '@mui/material/styles';

import i18n from "../../../utilities/i18n";
import { TAB1, TAB2, TAB3 } from "../../../utilities/staticConfigs";

import PatientsStatementManagement from "./StatmentManagementTab/PatientsStatementManagement";
import StatementConfigurationView from "./StatementConfiguration/StatementConfigurationView";

import { getStorage } from "../../../utilities/browserStorage";

import Notify from "../../commons/notify";
import LoadingContext from "../../../container/loadingContext";

import StatementReleaseTracker from "./StatementReleaseTrackerTab/StatementReleaseTracker";


const CustomIndicator = styled('span')(() => ({
  backgroundColor: 'white',
  height: 7,
  position: 'absolute',
  bottom: 0,
  width: 'calc(100% - 4px)',
  marginLeft: '2px'

}));

const StyledTabList = styled(TabList)`
& .MuiTabs-indicator {
  background-color: transparent !important;
  height: 0px !important;
}
`;

const StyledTab = styled(Tab)`
  &.tabMainMenu {
    min-height: 0 !important;
  }
`;

function StatementManagement() {
  //--------- Alert Messages States Start-------------
  const [showNotify, setShowNotify] = useState("hide");
  const [notifyDescription, setNotifyDescription] = useState("");
  const [notifyType, setNotifyType] = useState("success");
  const [notifyPrimaryText, setNotifyPrimaryText] = useState("")
  //--------- Alert Messages States End-------------

  //With useSelector hook, fetching the data from the store.
  const activeTab = useSelector((state) => state.statementMgData.commons.activeTab);
  const practicePK = getStorage("practice");
  const setShowLoadingBar = useContext(LoadingContext);

  const dispatch = useDispatch();

  function onTabChange(e, tab) {
    dispatch({ type: 'statementMgmnt/updateFieldValues', payload: { state: 'commons', field: 'activeTab', value: tab }, })
  }

  const showNotifyWindow = useCallback((action, type, desc, age = 3000, primaryText = null) => {
    if (action === "show") {
      setTimeout(() => {
        setShowNotify("hide");
        setNotifyPrimaryText("")
      }, age);
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
    setNotifyPrimaryText(primaryText)
  }, []);


  return (
    <React.Fragment>
      <Notify
        showNotify={showNotify}
        setShowNotify={setShowNotify}
        notifyDescription={notifyDescription}
        setNotifyType={setNotifyType}
        setNotifyDescription={setNotifyDescription}
        notifyType={notifyType}
        notifyPrimaryText={notifyPrimaryText}
      />
      <div className="col-md-12">
        <div className="box">
          <Form id="form_patient_info" autoComplete="off" className="margin-right15">
            <TabContext value={activeTab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <StyledTabList
                  TabIndicatorProps={{
                      children: <CustomIndicator />
                  }}
                  onChange={onTabChange}
                  scrollable
                  scrollButtons="auto"
                  aria-label="lab API tabs example"
                  variant="scrollable"
                >
                  <Tab
                    label={i18n.t("statementManagement.statementManagement")}
                    value={TAB1}
                    className="tabMainMenu"
                  />

                  <StyledTab
                    key={`statement-release-tracker`}
                    label={i18n.t('statementManagement.releaseTracker.tabName')}
                    value={TAB2}
                    className="tabMainMenu"
                  />

                  <Tab
                    label={i18n.t("statementManagement.statementConfiguration")}
                    value={TAB3}
                    className="tabMainMenu"
                  />

                </StyledTabList>
              </Box>

              <TabPanel value={TAB1} className="searchBoxTab box-content-white border-radius-topleft-0">
                <div className="padding-top4">
                  <PatientsStatementManagement
                    showNotifyWindow={showNotifyWindow}
                    setShowLoadingBar={setShowLoadingBar}
                    practicePk={practicePK}
                  />
                </div>
              </TabPanel>

              <TabPanel value={TAB2} className="searchBoxTab box-content-white border-radius-topleft-0">
                <div className="padding-top4">
                  <StatementReleaseTracker
                    showNotifyWindow={showNotifyWindow}
                    setShowLoadingBar={setShowLoadingBar}
                    practicePK={practicePK}
                  />
                </div>
              </TabPanel>

              <TabPanel value={TAB3} className="searchBoxTab box-content-white border-radius-topleft-0">
                <div className="padding-top4">
                  <StatementConfigurationView
                    showNotifyWindow={showNotifyWindow}
                    setShowLoadingBar={setShowLoadingBar}
                    practicePk={practicePK}
                  />
                </div>
              </TabPanel>

            </TabContext>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default StatementManagement;
