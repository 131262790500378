import React from 'react'

function CreditCardBox() {
    return (
        <div style={{
            padding: '12px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <div style={{
                fontSize: '13px',
                fontWeight: 600,
                marginBottom: '8px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <span>Offline Payment Modes</span>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px'
                }}>
                    <img
                        src="https://img.icons8.com/color/48/000000/mastercard-logo.png"
                        alt="Credit Card"
                        style={{
                            height: '20px',
                            objectFit: 'contain'
                        }}
                    />
                    <img
                        src="https://img.icons8.com/color/48/000000/check.png"
                        alt="Check Payment"
                        style={{
                            height: '20px',
                            objectFit: 'contain'
                        }}
                    />
                </div>
            </div>

            {/* Credit Card Fields */}
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px'
            }}>
                <div>
                    <label style={{ fontSize: '10px', color: '#666' }}>Card Number:</label>
                    <div style={{
                        height: '18px',
                        borderBottom: '1px solid #ccc',
                        marginTop: '2px'
                    }}></div>
                </div>

                <div>
                    <label style={{ fontSize: '10px', color: '#666' }}>Card Holder Name:</label>
                    <div style={{
                        height: '18px',
                        borderBottom: '1px solid #ccc',
                        marginTop: '2px'
                    }}></div>
                </div>

                <div style={{
                    display: 'flex',
                    gap: '15px'
                }}>
                    <div style={{ flex: 1 }}>
                        <label style={{ fontSize: '10px', color: '#666' }}>Expiry Date:</label>
                        <div style={{
                            height: '18px',
                            borderBottom: '1px solid #ccc',
                            marginTop: '2px'
                        }}></div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <label style={{ fontSize: '10px', color: '#666' }}>CVV:</label>
                        <div style={{
                            height: '18px',
                            borderBottom: '1px solid #ccc',
                            marginTop: '2px'
                        }}></div>
                    </div>
                </div>
            </div>

            {/* Card Types */}
            <div style={{ marginTop: '12px' }}>
                <label style={{ fontSize: '10px', color: '#666' }}>Card Type:</label>
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '8px',
                    marginTop: '4px'
                }}>
                    {[
                        { value: 'visa', img: 'visa.png', label: 'Visa' },
                        { value: 'master', img: 'mastercard-logo.png', label: 'Master' },
                        { value: 'discover', img: 'discover.png', label: 'Discover' },
                        { value: 'amex', img: 'amex.png', label: 'Amex' }
                    ].map((card) => (
                        <label key={card.value} style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '10px',
                            gap: '2px'
                        }}>
                            <input
                                type="checkbox"
                                value={card.value}
                                style={{
                                    width: '12px',
                                    height: '12px'
                                }}
                            />
                            <img
                                src={`https://img.icons8.com/color/48/000000/${card.img}`}
                                alt={`${card.label} Logo`}
                                style={{ height: '15px' }}
                            />
                            <span>{card.label}</span>
                        </label>
                    ))}
                </div>
            </div>

            <p style={{
                fontSize: '9px',
                color: '#666',
                marginTop: '8px',
                fontStyle: 'italic'
            }}>
                Please fill out the information and make sure all details are accurate and legible.
            </p>
        </div>
    )
}

export default CreditCardBox
