import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';

import HeaderSectionA from './TemplateComponents/HeaderSectionA';
import HeaderSectionB from './TemplateComponents/HeaderSectionB';
import HeaderSectionBillDetails from './TemplateComponents/HeaderSectionBillDetails';
import StatementTemplateTable from './TemplateComponents/StatementTemplateTable';
import TemplateFooterSection from './TemplateComponents/TemplateFooterSection';
import BackerCard from './TemplateComponents/BackerCard';
import TemplateEditor from './TemplateEditor';
import FooterAddressCard from './TemplateComponents/FooterAddressCard';
import CommonButton from '../../../commons/Buttons';

import service from "../Services";


const styleProperties = {
	wrapperProperties: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '20px',
		width: '100%',
		fontFamily: "'Times New Roman', Times, serif"
	},
	paperProperties: {
		// A4 size in pixels (assuming 96 DPI)
		width: '900px',  // 210mm
		height: '1250px', // 297mm
		padding: '40px',    // 1 inch margin
		backgroundColor: '#fff',
		position: 'relative'
	},
	colors: {
		primaryTextColor: "#0279c9",
		primaryBoxBackground: "#0279c9",
		secondaryBoxBackground: "#87c4e3",
		tertiaryBoxBackground: "#c9ddef"
	},
	fonts: {
		default: "'Times New Roman', Times, serif"
	}
}

/**
 * Statement Template Configuration Component
 * @component
 * @param {Object} props - The component props
 * @param {Function} props.showNotifyWindow - Function to show notification messages
 * @param {Function} props.setShowLoadingBar - Function to control the visibility of the loading bar
 * @param {number} props.practicePK - The primary key of the current practice
 * @returns {JSX.Element} The rendered StatementConfigurationView component
 */
function StatementConfigurationView({
	showNotifyWindow,
	setShowLoadingBar,
	practicePk
}) {
	const [openTemplateEditor, setOpenTemplateEditor] = useState(false);
	const [templateConfig, setTemplateConfig] = useState(null);
	const originalConfigRef = useRef(null);
	const [currentEditField, setCurrentEditField] = useState(null);


	const [logoFileInput, setLogoFileInput] = useState(null);
	const [qrFileInput, setQrFileInput] = useState(null);

	/**
	 * Side effect to execute api call on fetching template data
	 */
	useEffect(() => {
		getPracticeTemplateConfig();
	}, [])


	/**
	 * @description Callback to trigger when user clicks on edit icon of an editable field
	 * @param {string} fieldName 
	 */
	const handleEditorOpenClick = (fieldName) => {
		setCurrentEditField(fieldName);
		setOpenTemplateEditor(true);
	};

	/**
	 * Util to toggle the close state of the editor modal
	 */
	const handleEditorClose = () => {
		setOpenTemplateEditor(false);
		setCurrentEditField(null);
	};

	/**
	 * @description Get saved template config data of a practice and set it in the state
	 */
	const getPracticeTemplateConfig = async () => {
		setShowLoadingBar(true)
		try {
			const response = await service.getStatementConfig(practicePk);

			if (response.status == 200 && response.data) {
				const data = response.data;
				setTemplateConfig(data);
				// Store a copy of the response config for resetting or reverting purpose
				originalConfigRef.current = JSON.parse(JSON.stringify(data));
			} else {
				throw new Error("Error in getting practice statement config.")
			}
		} catch (error) {
			showNotifyWindow("show", "error", "An error occurred while fetching practice statement template");
		} finally {
			setShowLoadingBar(false);
		}
	}

	/**
	 * @description Handle template edit toggle button click
	 * incase already in the edit mode "On" then to toggle it back and revert the changes..
	 * calling the  getPracticeTemplateConfig,
	 * Otherwise, setting editEnabled to true of the templateConfigObject
	 */
	const handleTemplateEditMode = () => {
		if (templateConfig.editEnabled) {
			getPracticeTemplateConfig()
		}
		setTemplateConfig((prev) => { return { ...prev, editEnabled: !prev.editEnabled } })
	}

	/**
	 * @description Template Save || Update Method
	 * @async
	 */
	const handleTemplateUpdate = async () => {
		setShowLoadingBar(true);
		try {
			const payload = new FormData();

			payload.append('practice_pk', practicePk);

			// Append file inputs if they exist
			if (logoFileInput) {
				payload.append('logo', logoFileInput);
			}
			if (qrFileInput) {
				payload.append('qr_code', qrFileInput);
			}

			// Append template configuration data
			const configData = {
				practice_name: templateConfig.practice_name?.value || templateConfig.practice_name.default,
				payment_due_by_duration: templateConfig.payment_due_by_duration?.value || templateConfig.payment_due_by_duration.default,
				pay_online_text_a: templateConfig.pay_online_text_a?.value || templateConfig.pay_online_text_a.default,
				pay_by_mail_text: templateConfig.pay_by_mail_text?.value || templateConfig.pay_by_mail_text.default,
				phone: templateConfig.phone?.value || templateConfig.phone.default,
				email: templateConfig.email?.value || templateConfig.email.default,
				additional_disclosure_long_text: templateConfig.additional_disclosure_long_text?.value || templateConfig.additional_disclosure_long_text.default,
				check_payable_to: templateConfig.check_payable_to?.value || templateConfig.check_payable_to.default,

				// Handle nested objects
				mail_to: {
					mail_to_name: templateConfig.mail_to?.mail_to_name?.value || templateConfig.mail_to.mail_to_name.default,
					text_1: templateConfig.mail_to?.text_1?.value || templateConfig.mail_to.text_1.default,
					text_2: templateConfig.mail_to?.text_2?.value || templateConfig.mail_to.text_2.default,
					text_3: templateConfig.mail_to?.text3?.value || templateConfig.mail_to.text_3.default
				},

				statement_from_address: {
					mail_from_name: templateConfig.statement_from_address?.mail_from_name?.value || templateConfig.statement_from_address?.mail_from_name?.default,
					text_1: templateConfig.statement_from_address?.text_1.value || templateConfig.statement_from_address.text_1.default,
					text_2: templateConfig.statement_from_address?.text_2.value || templateConfig.statement_from_address.text_2.default,
					text_3: templateConfig.statement_from_address?.text_3.value || templateConfig.statement_from_address.text_3.default
				}
			};

			payload.append('templateConfig', JSON.stringify(configData));

			let response;

			if (templateConfig.id) {
				response = await service.updateStatementConfig(templateConfig.id, payload);
			} else {
				response = await service.saveTemplateConfig(payload);
			}

			if ([201, 200].includes(response.status)) {
				showNotifyWindow("show", 'success', 'Template Saved successfully');
				getPracticeTemplateConfig()
			} else if (Array.isArray(response.data?.errors)) {
				const errorText = response.data.errors.join(', ');
				const truncatedError = errorText.length > 100
					? `${errorText.slice(0, 100)}...`
					: errorText;

				showNotifyWindow("show", 'error', truncatedError);
			} else {
				throw new Error("Un-known error")
			}

		} catch (error) {
			showNotifyWindow("show", 'error', 'An error occurred while updating the template');
		} finally {
			setShowLoadingBar(false);
		}
	};



	/**
	 * Fallback logic to call execute immediate return incase template config is nullish or falsy
	 */
	if (!templateConfig) {
		return (
			<></>
		)
	}

	try {
		return (
			<>
				{/* EDITOR DRAGGABLE MODAL */}
				<TemplateEditor
					openTemplateEditor={openTemplateEditor}
					handleEditorClose={handleEditorClose}
					currentEditField={currentEditField}
					templateConfig={templateConfig}
					setTemplateConfig={setTemplateConfig}
					setQrFileInput={setQrFileInput}
					setLogoFileInput={setLogoFileInput}
					originalConfigRef={originalConfigRef}
				/>

				{/* First Page */}
				<div style={styleProperties.wrapperProperties}>
					<Paper
						sx={{
							...styleProperties.paperProperties,
							position: 'relative'
						}}
						elevation={4}
					>
						{/* Action Buttons Container */}
						<div style={{
							position: 'absolute',
							right: '-200px',
							top: '40px',
							display: 'flex',
							flexDirection: 'column',
							gap: '10px',
							zIndex: 1
						}}>
							<CommonButton
								icon="save"
								variant="contained"
								label="Update Template"
								id="update-template"
								disabled={!templateConfig.editEnabled}
								style={{
									boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
									borderRadius: '4px',
									height: '36px',
									width: '180px',
									backgroundColor: styleProperties.colors.primaryTextColor,
									color: '#fff',
									transition: 'all 0.3s ease'
								}}
								onClick={handleTemplateUpdate}
							/>
							<CommonButton
								icon={templateConfig.editEnabled ? "cancel" : "edit"}
								variant="outlined"
								label={templateConfig.editEnabled ? "Cancel" : " Enable Edit Mode"}
								id="enable-edit-mode"
								style={{
									boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
									borderRadius: '4px',
									height: '36px',
									width: '180px',
									backgroundColor: '#fff',
									color: styleProperties.colors.primaryTextColor,
									border: `1px solid ${styleProperties.colors.primaryTextColor}`,
									transition: 'all 0.3s ease'
								}}
								onClick={handleTemplateEditMode}
							/>
						</div>

						<HeaderSectionA templateConfig={templateConfig} styleProperties={styleProperties} handleEditorOpenClick={handleEditorOpenClick} />

						{/* SECTION - 2 Page Number Renderer  */}
						<HeaderSectionB styleProperties={styleProperties} />

						{/* SECTION -3 BILL SUMMARY & PAYMENT OPTIONS */}
						<HeaderSectionBillDetails templateConfig={templateConfig} styleProperties={styleProperties} handleEditorOpenClick={handleEditorOpenClick} />

						{/* SECTION 4 - STATEMENT TABLE */}
						<StatementTemplateTable styleProperties={styleProperties} />

						{/* DASHED PAGE DIVIDER */}
						<div style={{ borderBottom: '2px dashed #E0E0E0', width: '100%', height: '1' }} />

						{/* FOOTER SECTION - 1 */}
						<TemplateFooterSection styleProperties={styleProperties} templateConfig={templateConfig} handleEditorOpenClick={handleEditorOpenClick} />

						{/* FOOTER SECTION - 2 - Patient & Practice Address Details */}
						<FooterAddressCard styleProperties={styleProperties} templateConfig={templateConfig} handleEditorOpenClick={handleEditorOpenClick} />
					</Paper>
				</div>

				{/* Backer Page */}
				<div style={styleProperties.wrapperProperties}>
					<Paper
						sx={{
							...styleProperties.paperProperties,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-end'
						}}
						elevation={4}
					>
						<div style={{
							width: '100%',
							marginBottom: '40px'
						}}>
							<BackerCard styleProperties={styleProperties} />
						</div>
					</Paper>
				</div>
			</>
		)
	} catch (error) {
		console.error(error)
	}
}

StatementConfigurationView.propTypes = {
	showNotifyWindow: PropTypes.func.isRequired,
	setShowLoadingBar: PropTypes.func.isRequired,
	practicePK: PropTypes.number.isRequired
};

export default StatementConfigurationView;
